import { render, staticRenderFns } from "./UserIcon2White.vue?vue&type=template&id=1f6bbd0c"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UserIcon2White.vue?vue&type=custom&index=0&blockType=svg&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg&fill=none&viewBox=0%200%2024%2024&stroke-width=1.5&stroke=currentColor&class=w-6%20h-6"
if (typeof block0 === 'function') block0(component)

export default component.exports