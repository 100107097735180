<template>
  <div>
    <label
      class="flex items-center gap-2 text-gray-700 text-sm font-medium mb-2"
      :for="attribute"
    >
      <!-- Country Label -->
      <country-flag v-if="country" :country="country" size="small" />
      {{ label }}
    </label>
    <div class="relative">
      <input
        class="
          appearance-none
          bg-gray-100
          rounded
          w-full
          py-2
          px-3
          text-gray-800
          leading-tight
          focus:outline-none
        "
        :class="[
          submitStatus === 'ERROR' && v.$error ? 'border-red-500' : '',
          hasDropDown ? 'isDropDown' : '',
        ]"
        :id="attribute"
        :name="attribute"
        :ref="attribute"
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        :readonly="hasDropDown || readonly"
      />
      <div
        v-if="hasDropDown"
        class="
          absolute
          flex
          border border-transparent
          right-0
          top-0
          h-full
          w-10
          isDropDown
        "
      >
        <div
          class="
            flex
            items-center
            justify-center
            rounded-tl rounded-bl
            z-10
            text-gray-800 text-lg
            h-full
            w-full
          "
        >
          <svg
            class="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
    <div>
      <div v-if="submitStatus === 'ERROR'" class="pt-2">
        <div v-for="(errorMessage, index) in errorMessages" :key="index">
          <p v-if="!v[index]" class="text-red-500 text-xs">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
export default {
  components: {
    CountryFlag,
  },
  props: [
    "label",
    "attribute",
    "placeholder",
    "errorMessages",
    "value",
    "submitStatus",
    "v",
    "hasDropDown",
    "country",
    "readonly",
  ],
};
</script>
<style scoped>
.isDropDown {
  cursor: pointer;
  caret-color: transparent;
}
</style>