import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from "vuex-persistedstate"
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    user: null,
    token: null,
    startTokenTime: null,
    newProductImages: [],
    products: [],
    users: [],
    notifications: {
      date: 0,
      request: 0
    },
    sidebar: {
      isOpen: false
    },
    sort: {
      title: '',
      by: false
    },

  },

  getters: {
    auth: state => { return state.auth },
    notifications: state => { return state.notifications },
    getItemToken: state => { return state.token },
    getSort: state => { return state.sort },
    isLoggedIn: state => { return !!state.token },
  },

  mutations: {
    TOGGLE_SIDEBAR(state) {
      return state.sidebar.isOpen = !state.sidebar.isOpen
    },
    LOAD_USER(state, value) {
      return state.user = value
    },
    SET_TOKEN(state, value) {
      return state.token = value
    },
    UPDATE_USER_SHOP(state, value) {
      return state.user.shop = value
    },
    REMOVE_USER(state) {
      return state.user = null
    },
    REMOVE_TOKEN(state) {
      return state.token = null
    },
    SET_PRODUCT_IMAGES(state, value) {
      return state.newProductImages = value
    },
    SET_TOKEN_START_TIME(state, value) {
      return state.startTokenTime = value;
    },
    REMOVE_PRODUCT_IMAGE(state, value) {
      return state.newProductImages.splice(value, 1);
    },
    EMPTY_PRODUCT_IMAGES(state) {
      return state.newProductImages = []
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications.date = payload.date
      state.notifications.request = payload.request
    },
    SET_SORT(state, payload) {
      return state.sort = payload
    },
    SET_PRODUCTS(state, payload) {
      return state.products = payload
    },
    SET_USERS(state, payload) {
      return state.users = payload
    },
    SET_FRISBO_TOKEN(state, payload) {
      return state.frisbo = payload
    },
    SET_FRISBO_ORDER(state, payload) {
      return state.frisbo.order = payload
    }
  },
  actions: {
    toggleSidebar({ commit }) {
      commit('TOGGLE_SIDEBAR');
    },
    loadUser({ commit }, value) {
      commit('LOAD_USER', value);
    },
    setToken({ commit }, value) {
      commit('SET_TOKEN', value);
    },
    updateUserShop({ commit }, value) {
      commit('UPDATE_USER_SHOP', value);
    },
    removeToken({ commit }) {
      commit('REMOVE_TOKEN');
    },
    removeUser({ commit }) {
      commit('REMOVE_USER');
    },
    setImages({ commit }, value) {
      commit('SET_PRODUCT_IMAGES', value);
    },
    setTokenStartTime({ commit }, value) {
      commit('SET_TOKEN_START_TIME', value);
    },
    removeImage({ commit }, value) {
      commit('REMOVE_PRODUCT_IMAGE', value);
    },
    emptyImages({ commit }) {
      commit('EMPTY_PRODUCT_IMAGES');
    },
    checkAuth({ commit }) {
      commit('REMOVE_TOKEN');
    },
    setNotifications({ commit }, payload) {
      commit('SET_NOTIFICATIONS', payload);
    },
    setSort({ commit }, payload) {
      commit('SET_SORT', payload);
    },
    getProducts({ commit },  request) {
      return new Promise((resolve, reject) => {
        axios({ url: request.url, data: request.data, method: "GET" })
            .then(resp => {
              commit("SET_PRODUCTS", resp.data.products);
              resolve(resp);
            })
            .catch(err => {
              reject(err);
            });
      });
    },
    getUsers({ commit },  request) {
      return new Promise((resolve, reject) => {
        axios({ url: request.url, data: request.data, method: "GET" })
            .then(resp => {
              commit("SET_USERS", resp.data.users);
              resolve(resp);
            })
            .catch(err => {
              reject(err);
            });
      });
    },
  },
});