var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center h-screen max-w-full"},[_c('div',{staticClass:"w-full md:w-2/3 px-4"},[_c('form',{staticClass:"bg-white rounded px-8 pt-6 pb-8 mb-4",on:{"submit":function($event){$event.preventDefault();}}},[_c('h1',{staticClass:"font-bold text-xl mb-2 text-black"},[_vm._v("Change password")]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"block text-black text-sm font-bold mb-2",attrs:{"for":"passwordOld"}},[_vm._v(" Old password ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.old.$model),expression:"$v.password.old.$model",modifiers:{"trim":true}}],staticClass:"appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none",class:[
            _vm.submitStatus === 'ERROR' && _vm.$v.password.old.$error
              ? 'border-red-500'
              : '',
          ],attrs:{"id":"passwordOld","type":"password","placeholder":"********"},domProps:{"value":(_vm.$v.password.old.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.password.old, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"h-2"},[(_vm.submitStatus === 'ERROR')?_c('div',[(!_vm.$v.password.old.required)?_c('p',{staticClass:"text-red-500 text-xs"},[_vm._v(" Password is required ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"block text-black text-sm font-bold mb-2",attrs:{"for":"passwordNew"}},[_vm._v(" New passwort ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.new.$model),expression:"$v.password.new.$model",modifiers:{"trim":true}}],staticClass:"appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none",class:[
            _vm.submitStatus === 'ERROR' && _vm.$v.password.new.$error
              ? 'border-red-500'
              : '',
          ],attrs:{"id":"passwordNew","type":"password","placeholder":"********"},domProps:{"value":(_vm.$v.password.new.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.password.new, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"h-8"},[(_vm.submitStatus === 'ERROR')?_c('div',[(!_vm.$v.password.new.specialCharAlphaNumbers)?_c('p',{staticClass:"text-red-500 text-xs"},[_vm._v(" The password requires at least 8 characters incl Numbers and special characters ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"flex items-center justify-between"},[(_vm.submitStatus !== 'PENDING')?_c('button',{staticClass:"bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none",attrs:{"type":"submit"},on:{"click":_vm.changePass}},[_vm._v(" Save new password ")]):_vm._e(),(_vm.submitStatus === 'PENDING')?_c('button',{staticClass:"inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 cursor-not-allowed",attrs:{"type":"button","disabled":""}},[_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-5 w-5 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]),_vm._v(" Saving... ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }