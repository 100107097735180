<template>
  <div v-if="order">
    <div class="flex justify-center">
      <div class="bg-white overflow-hidden sm:rounded-lg w-full shadow relative">
        <h1 class="absolute right-4 top-3 text-2xl">Order number: <span class="text-black">23{{ order.id }}</span></h1>
        <div class="px-4 py-5 sm:px-6">

          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 mb-2">Order info</h3>
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Arrives:</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.date_from | moment("DD.MM.YYYY")}} - {{ order.time_from }}</dd>
              <dt class="text-sm font-medium text-gray-500">Leaves:</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.date_to | moment("DD.MM.YYYY")}} - {{ order.time_to }}</dd>
              <dt class="text-sm font-medium text-gray-500">Days total:</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.days_total }}</dd>
            </div>
          </dl>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 mb-2">Customer info</h3>
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Name:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.full_name}}</dd>
                <dt class="text-sm font-medium text-gray-500">  Email:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.email }}</dd>
                <dt class="text-sm font-medium text-gray-500">Phone:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.phone }}</dd>
              </div>
            </dl>
            </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 mb-2">Payment info</h3>
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Total:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.total}} rsd</dd>
                <dt class="text-sm font-medium text-gray-500">  For mb:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{order.total*0.8 }} rsd</dd>
                <dt class="text-sm font-medium text-gray-500">For carport:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.total*0.2 }} rsd</dd>
              </div>
            </dl>
            </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mt-4 mb-2">Car info</h3>
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">Car color:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.car_color }}</dd>
                <dt class="text-sm font-medium text-gray-500">Car description: </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.car_description }}</dd>
                <dt class="text-sm font-medium text-gray-500">License plate:</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ order.car_description }}</dd>
              </div>
            </dl>
          </div>
  </div>
        </div>
      </div></div>
</template>

<script>
export default {
  data() {
    return {
      order: {},
      modalEdit: false,
    };
  },
  mounted() {
    this.loadObjects();
  },
  methods: {
    closeModal() {
      this.modalEdit = false;
    },
    async loadObjects() {
      let orderId = this.$route.params.id;
      this.axios
          .get(process.env.VUE_APP_BACKEND_URL + "/order/show/" + orderId)
          .then((dataRes) => {
            this.order = dataRes.data.order;
          })
          .catch((error) => {
            this.$vToastify.error(error.request.response.message, "Error");
          });
    },
  }
}
</script>
