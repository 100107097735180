<template>
  <div class="flex items-center justify-center h-screen max-w-full">
    <div class="w-full md:w-2/3 px-4">
      <!-- Password reset Start -->
      <form @submit.prevent class="bg-white rounded px-8 pt-6 pb-8 mb-4">
        <h1 class="font-bold text-xl mb-2 text-black">Edit profile</h1>
        <!-- username input field with validation rules -->
        <div class="mb-4">
          <label class="block text-black text-sm font-bold mb-2" for="username">
            User name
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && $v.name.$error
                ? 'border-red-500'
                : '',
            ]"
            id="username"
            type="text"
            v-model.trim="$v.name.$model"
            placeholder="User name"
          />
          <div class="h-2">
            <div v-if="submitStatus === 'ERROR'">
              <p v-if="!$v.name.required" class="text-red-500 text-xs">
                User name is required
              </p>
              <p v-if="$v.name.minLegth" class="text-red-500 text-xs">
                User name needs 3 characters at least
              </p>
            </div>
          </div>
        </div>
        <!-- email input field with validation rules -->
        <div class="mb-4">
          <label class="block text-black text-sm font-bold mb-2" for="email">
            E-Mail Address
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus == 'ERROR' && $v.email.$error
                ? 'border-red-500'
                : '',
            ]"
            id="email"
            type="text"
            v-model.trim="$v.email.$model"
            placeholder="user@gmail.com"
          />
          <div class="h-2">
            <div v-if="submitStatus == 'ERROR'">
              <p v-if="!$v.email.required" class="text-red-500 text-xs">
                E-Mail Address is required
              </p>
              <p v-if="!$v.email.email" class="text-red-500 text-xs">
                Please insert a valid E-Mail Addresse
              </p>
            </div>
          </div>
        </div>
        <!-- password input field with validation rules -->
        <div class="mb-2">
          <label class="block text-black text-sm font-bold mb-2" for="password">
            Password
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus == 'ERROR' && $v.password.$error
                ? 'border-red-500'
                : '',
            ]"
            v-model.trim="$v.password.$model"
            id="password"
            type="password"
            placeholder="********"
          />
          <div class="h-8">
            <div v-if="submitStatus == 'ERROR'">
              <p v-if="!$v.password.required" class="text-red-500 text-xs">
                Password is required
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="
              bg-blue-600
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none
            "
            type="submit"
            @click="updateProfile"
            v-if="submitStatus !== 'PENDING'"
          >
            Save
          </button>
          <button
            type="button"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              leading-6
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-500
              focus:outline-none
              focus:border-indigo-700
              focus:shadow-outline-indigo
              active:bg-indigo-700
              transition
              ease-in-out
              duration-150
              cursor-not-allowed
            "
            disabled=""
            v-if="submitStatus === 'PENDING'"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Saving...
          </button>
        </div>
      </form>
      <!-- Password reset form End -->
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "EditUserProfile",
  data() {
    return {
      name: "",
      password: "",
      email: "",
      submitStatus: null,
    };
  },
  validations: {
    password: {
      required,
    },
    name: {
      required,
      minLength: minLength(3),
    },
    email: {
      required,
      email,
    },
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    updateProfile() {
      // define a form data
      let data = new FormData();

      // append the values in the form data
      data.append("name", this.name);
      data.append("email", this.email);
      data.append("password", this.password);

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/admin/profile/update`, data)
          .then((dataRes) => {
            this.$vToastify.success(dataRes.data.message, "Success");
            this.$store.dispatch("loadUser", dataRes.data.user);
            this.name = dataRes.data.user.name;
            this.email = dataRes.data.user.email;
            this.password = "";
          })
          .catch((error) => {
            let response = JSON.parse(error.request.response);
            this.$vToastify.error(response.message, "Error");
          });
      }
    },

    async loadUser() {
      this.axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/admin/profile/edit`, "")
        .then((dataRes) => {
          this.name = dataRes.data.user.name;
          this.email = dataRes.data.user.email;
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>