<template>
  <header
    class="
      flex
      justify-between
      items-center
      py-4
      px-6
      bg-white
      border-b-4 border-gray-200
    "
  >
    <div class="flex items-center">
      <button
        @click="toggleSidebar()"
        class="text-gray-500 focus:outline-none lg:hidden cursor-pointer"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6H20M4 12H20M4 18H11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div v-if="user" class="relative mx-4 lg:mx-0">
        <p class="text-black font-bold">{{ user.name }}</p>
      </div>
    </div>

    <div class="flex items-center">
      <div class="relative">
        <div
          v-if="user"
          @click="dropdownOpen = !dropdownOpen"
          class="
            flex
            items-center
            justify-center
            text-white
            font-bold
            relative
            z-10
            h-10
            w-10
            rounded-full
            overflow-hidden
            bg-blue-300
            focus:outline-none
            text-2xl
            cursor-pointer
          "
        >
          {{ user.name.charAt(0) }}
        </div>

        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="
            absolute
            right-0
            mt-2
            py-2
            w-48
            bg-white
            rounded-md
            shadow-xl
            z-20
          "
        >
          <router-link to="/profil/edit" class="block text-sm">
            <div
              class="
                flex
                items-center
                py-2
                px-4
                text-gray-700
                hover:bg-indigo-600 hover:text-white
              "
            >
              <svg
                class="h-5 w-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <g>
                  <g>
                    <path
                      d="M12,13.8c-3.8,0-6.8-3.1-6.8-6.9C5.2,3.1,8.2,0,12,0c3.8,0,6.8,3.1,6.8,6.9C18.8,10.7,15.8,13.8,12,13.8z M12,1.2
			c-3.1,0-5.7,2.5-5.7,5.7s2.5,5.7,5.7,5.7s5.6-2.6,5.6-5.7S15.1,1.2,12,1.2z"
                    />
                  </g>
                  <g>
                    <path
                      d="M22.2,24c-0.3,0-0.6-0.3-0.6-0.6c0-5.3-4.3-9.6-9.6-9.6s-9.6,4.3-9.6,9.6c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6
			c0-6,4.8-10.8,10.8-10.8s10.8,4.9,10.8,10.8C22.8,23.7,22.5,24,22.2,24z"
                    />
                  </g>
                </g>
              </svg>
              Profil
            </div>
          </router-link>
          <!-- Change Pass Start -->
          <router-link to="/profil/change-password" class="block text-sm">
            <div
              class="
                flex
                items-center
                py-2
                px-4
                text-gray-700
                hover:bg-indigo-600 hover:text-white
              "
            >
              <svg
                class="h-5 w-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <g>
                  <path
                    d="M12.7,13.1c-0.8-0.3-1.7,0-2.2,0.6c-0.5,0.7-0.7,1.6-0.2,2.3c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.1,0,0.2c0,0.5-0.1,1.1,0.2,1.5c0.5,0.9,1.8,1.1,2.5,0.3c0.3-0.3,0.4-0.7,0.4-1.1c0-0.2,0-0.4,0-0.5c0,0,0,0,0,0
		c0-0.1,0-0.3,0-0.4c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1C13.8,16,14,15.5,14,15C14,14.1,13.5,13.4,12.7,13.1z M11.2,15.6
		C11.2,15.6,11.2,15.6,11.2,15.6C11.3,15.6,11.2,15.6,11.2,15.6z M13,15.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0
		c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0,0.7c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0.1
		c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0
		c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
		c0-0.1,0-0.1,0-0.2c0,0,0-0.2,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1
		c0,0,0.1-0.2,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0.1c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
		c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1
		C13,15,13,15.1,13,15.1z"
                  />
                  <path
                    d="M20,10c-0.7,0-1.3,0-2,0c0-1.3,0-2.7,0-4c0-0.8,0-1.6-0.4-2.4c-0.5-1.2-1.6-2.1-2.9-2.4C14,0.9,13.2,1,12.4,1
		c-0.7,0-1.4,0-2.1,0C7.8,1,6,3.1,6,5.5C6,7,6,8.5,6,10c-1.6,0-3.7-0.3-3.9,2c-0.1,2.2,0,3.4,0,5.5c0,1.8-0.7,5.3,1.8,5.6
		c4.9,0,9.7,0,14.6,0c1.5,0,3.4,0.1,3.4-2.1c0.1-2.5,0-5,0-7.5C22,11.7,22.4,10.1,20,10z M7.2,4.4c0.3-1.2,1.3-2,2.4-2.3
		C10.3,2,11,2,11.7,2c0.7,0,1.3,0,2,0c1.2,0,2.2,0.6,2.8,1.6C16.9,4.3,17,5,17,5.8c0,1.4,0,2.8,0,4.1c-0.9,0-1.8,0-2.6,0
		c-2.4,0-4.9,0-7.3,0c0-1.2,0-2.5,0-3.7C7,5.7,7,5,7.2,4.4z M20.1,22c-1.5,0.2-3.1,0-4.6,0c-3.8,0-7.7,0-11.5,0
		c-1.2-0.1-0.9-1.9-0.9-2.8c0-1.4,0-2.9,0-4.3c0-1.2,0-1.4,0-2.5c0-0.4,0-0.8,0.3-1.1C3.6,11,3.9,11,4.2,11c4.3-0.2,8.6,0,12.8,0
		c0.8,0,1.6,0,2.4,0c0.7,0,1.4-0.1,1.5,0.9c0.1,0.7,0,0.5,0,1.2c0,1.3,0,2.5,0,3.8c0,1.1,0,2.3,0,3.4C21,21.1,21,21.9,20.1,22z"
                  />
                </g>
              </svg>
              Change password
            </div>
          </router-link>
          <!-- Change Pass End -->
          <div
            @click="logout()"
            class="
              flex
              items-center
              py-2
              px-4
              text-gray-700
              hover:bg-indigo-600 hover:text-white
              cursor-pointer
            "
          >
            <svg
              class="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M16.6,13.4v7.9H4V2.7h12.6v7.7h1V2.2c0-0.3-0.2-0.5-0.5-0.5H3.5C3.2,1.6,3,1.9,3,2.2v19.7c0,0.3,0.2,0.5,0.5,0.5h13.6
	c0.3,0,0.5-0.2,0.5-0.5v-8.5H16.6z"
              />
              <g>
                <path
                  d="M24,12.1c0-0.1,0-0.1,0-0.2s0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2l-4-4c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7
		l3.1,3.1h-8.6c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h8.6l-3.4,3.4c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.2,0.4,0.2
		s0.3-0.1,0.4-0.2l4.3-4.3C23.9,12.2,23.9,12.1,24,12.1z"
                />
              </g>
            </svg>
            <div class="block px-2 text-sm">Log out</div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isOpen: true,
      dropdownOpen: false,
    };
  },
  computed: {
    ...mapState(["user", "token"]),
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar", this.event);
    },
    logout() {
      this.axios
        .post(`${process.env.VUE_APP_BACKEND_URL}/admin/logout`)
        .then(() => {
          localStorage.removeItem("token");
          this.$store.dispatch("removeToken", null);
          this.$store.dispatch("removeUser", null);
          this.$router.push("/");
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>