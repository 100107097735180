<template>
  <div class="flex z-10">
    <!-- Backdrop -->
    <div
      :class="sidebar.isOpen ? 'block' : 'hidden'"
      class="
        fixed
        z-30
        inset-0
        bg-black
        opacity-50
        transition-opacity
        lg:hidden
      "
      @click="toggleSidebar()"
    ></div>
    <!-- End Backdrop -->
    <div
      :class="
        sidebar.isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
      "
      class="
        fixed
        z-50
        bg-white
        inset-y-0
        left-0
        w-64
        transition
        duration-300
        transform
        overflow-y-auto
        lg:translate-x-0 lg:static lg:inset-0
      "
    >
      <div class="flex items-center m-4">
        <div class="w-full">
          <p class="font-extrabold text-2xl">
            Carport <span class="text-xs font-normal">/dashboard</span>
          </p>
          <!-- <img :src="path + `/assets/images/logo.svg`" alt="" /> -->
        </div>
      </div>

      <nav class="mt-10">
        <!-- Routes START -->
        <div>
          <!-- Home Route -->
          <router-link
            class="flex items-center mt-4 py-2 px-6"
            :class="[
              $route.name === 'Home'
                ? 'active-menu-item'
                : 'inactive-menu-item',
            ]"
            to="/home"
            @click.native="toggleSidebar()"
          >
            <HomeIcon />

            <span class="mx-4">Home</span>
          </router-link>
          <!-- Orders Route -->
          <router-link
            class="flex items-center mt-4 py-2 px-6"
            :class="[
              $route.name === 'Orders'
                ? 'active-menu-item'
                : 'inactive-menu-item',
            ]"
            to="/orders"
            @click.native="toggleSidebar()"
          >
            <OrderIcon />
            <span class="mx-4">Orders</span>
          </router-link>
          <router-link
            class="flex items-center mt-4 py-2 px-6"
            :class="[
              $route.name === 'Prices'
                ? 'active-menu-item'
                : 'inactive-menu-item',
            ]"
            to="/prices"
            @click.native="toggleSidebar()"
          >
            <CategoryIcon />
            <span class="mx-4">Prices</span>
          </router-link>
          <router-link
            class="flex items-center mt-4 py-2 px-6"
            :class="[
              $route.name === 'Customers'
                ? 'active-menu-item'
                : 'inactive-menu-item',
            ]"
            to="/customers"
            @click.native="toggleSidebar()"
          >
            <CustomerIcon />
            <span class="mx-4">Customers</span>
          </router-link>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import HomeIcon from "./svg/HomeIcon.vue";
import OrderIcon from "./svg/OrderIcon.vue";
import ProductIcon from "./svg/ProductIcon.vue";
import AddProductIcon from "./svg/AddProductIcon.vue";
import CategoryIcon from "./svg/CategoryIcon.vue";
import StorageIcon from "./svg/StorageIcon.vue";
import VoucherIcon from "./svg/VoucherIcon.vue";
import CustomerIcon from "./svg/CustomerIcon.vue";
import ContentIcon from "./svg/ContentIcon.vue";

export default {
  components: {
    HomeIcon,
    OrderIcon,
    CategoryIcon,
    CustomerIcon,
  },
  data() {
    return {
      path: process.env.VUE_APP_BACKEND_ASSETS_PATH,
    };
  },
  computed: {
    ...mapState(["sidebar", "user"]),
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar", this.event);
    },
    closeSidebar() {
      this.$store.dispatch("toggleSidebar", false);
    },
  },
};
</script>