var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex z-10"},[_c('div',{staticClass:"fixed z-30 inset-0 bg-black opacity-50 transition-opacity lg:hidden",class:_vm.sidebar.isOpen ? 'block' : 'hidden',on:{"click":function($event){return _vm.toggleSidebar()}}}),_c('div',{staticClass:"fixed z-50 bg-white inset-y-0 left-0 w-64 transition duration-300 transform overflow-y-auto lg:translate-x-0 lg:static lg:inset-0",class:_vm.sidebar.isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'},[_vm._m(0),_c('nav',{staticClass:"mt-10"},[_c('div',[_c('router-link',{staticClass:"flex items-center mt-4 py-2 px-6",class:[
            _vm.$route.name === 'Home'
              ? 'active-menu-item'
              : 'inactive-menu-item',
          ],attrs:{"to":"/home"},nativeOn:{"click":function($event){return _vm.toggleSidebar()}}},[_c('HomeIcon'),_c('span',{staticClass:"mx-4"},[_vm._v("Home")])],1),_c('router-link',{staticClass:"flex items-center mt-4 py-2 px-6",class:[
            _vm.$route.name === 'Orders'
              ? 'active-menu-item'
              : 'inactive-menu-item',
          ],attrs:{"to":"/orders"},nativeOn:{"click":function($event){return _vm.toggleSidebar()}}},[_c('OrderIcon'),_c('span',{staticClass:"mx-4"},[_vm._v("Orders")])],1),_c('router-link',{staticClass:"flex items-center mt-4 py-2 px-6",class:[
            _vm.$route.name === 'Prices'
              ? 'active-menu-item'
              : 'inactive-menu-item',
          ],attrs:{"to":"/prices"},nativeOn:{"click":function($event){return _vm.toggleSidebar()}}},[_c('CategoryIcon'),_c('span',{staticClass:"mx-4"},[_vm._v("Prices")])],1),_c('router-link',{staticClass:"flex items-center mt-4 py-2 px-6",class:[
            _vm.$route.name === 'Customers'
              ? 'active-menu-item'
              : 'inactive-menu-item',
          ],attrs:{"to":"/customers"},nativeOn:{"click":function($event){return _vm.toggleSidebar()}}},[_c('CustomerIcon'),_c('span',{staticClass:"mx-4"},[_vm._v("Customers")])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center m-4"},[_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"font-extrabold text-2xl"},[_vm._v(" Carport "),_c('span',{staticClass:"text-xs font-normal"},[_vm._v("/dashboard")])])])])
}]

export { render, staticRenderFns }