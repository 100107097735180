<template>
  <div class="w-full text-center py-5 px-16 text-black font-bold text-2xl">
    <!-- <img :src="path + `/assets/images/logo.svg`" alt="" /> -->
    CARPORT
  </div>
</template>

<script>
export default {
  name: "Logo",
  data() {
    return {
      path: process.env.VUE_APP_BACKEND_ASSETS_PATH,
    };
  },
};
</script>
