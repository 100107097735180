<template>
  <div class="min-h-full flex h-screen">
    <div class="flex-1 flex flex-col justify-center">
      <div class="mx-auto w-full max-w-sm lg:w-96">
      <!-- Logo Start -->
      <Logo />
      <!-- Logo End -->
      <!-- Password reset Start -->
      <form @submit.prevent class="rounded px-8 pt-6 pb-8 mb-4">
        <h1 class="font-bold text-xl mb-2 text-black">Set new password</h1>
        <div class="mb-4 mt-4">
          <label class="block text-black text-sm font-bold mb-2" for="username">
            E-Mail Address
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && $v.email.$error
                ? 'border-red-500'
                : '',
            ]"
            id="email"
            type="text"
            v-model.trim="$v.email.$model"
            placeholder="z.B. lara.neumayer@gmail.com"
          />
          <div class="h-2">
            <div v-if="submitStatus === 'ERROR'">
              <p v-if="!$v.email.required" class="text-red-500 text-xs">
                Please enter email address
              </p>
              <p v-if="!$v.email.email" class="text-red-500 text-xs">
                Please enter correct email address
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label class="block text-black text-sm font-bold mb-2" for="password">
            Password
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && !$v.password.specialCharAlphaNumbers
                ? 'border-red-500'
                : '',
            ]"
            v-model.trim="$v.password.$model"
            id="password"
            type="password"
            placeholder="********"
          />
          <div class="h-8">
            <div v-if="submitStatus === 'ERROR'">
              <p
                v-if="!$v.password.specialCharAlphaNumbers"
                class="text-red-500 text-xs"
              >
                The password requires at least 8 characters inclusive Numbers
                and special characters
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="
              bg-blue-600
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none
            "
            type="submit"
            @click="resetPassword"
            v-if="submitStatus !== 'PENDING'"
          >
            Set password
          </button>
          <button
            type="button"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              leading-6
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-500
              focus:outline-none
              focus:border-indigo-700
              focus:shadow-outline-indigo
              active:bg-indigo-700
              transition
              ease-in-out
              duration-150
              cursor-not-allowed
            "
            disabled=""
            v-if="submitStatus === 'PENDING'"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Set password...
          </button>
        </div>
      </form>
      <!-- Password reset form End -->
      <!-- Back to Login Start -->
      <router-link
        class="
          inline-block
          align-baseline
          text-xs text-blue-500
          hover:text-blue-800
          px-8
        "
        to="/"
      >
        Back to Login
      </router-link>
      <!-- Back to Login End -->
    </div>
  </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" src="/images/auth.webp" alt="Auth screen image">
    </div>
  </div>
</template>

<script>
import Logo from "../../components/Logo";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    Logo,
  },
  name: "SetNewPassword",
  data() {
    return {
      submitStatus: null,
      email: "",
      password: "",
      token: "",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      specialCharAlphaNumbers(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          /\W|_/.test(password) && // checks for special char
          password.length >= 8
        );
      },
    },
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  methods: {
    async resetPassword() {
      this.$v.$touch();
      let data = {
        email: this.email,
        password: this.password,
        token: this.token,
      };

      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        try {
          this.submitStatus = "PENDING";

          const dataRes = await this.axios.post(
            process.env.VUE_APP_BACKEND_URL + "/admin/set-new-pass",
            data
          );
          this.$vToastify.success(dataRes.data.message, "Success");
          this.email = "";
          this.password = "";
        } catch (error) {
          let response = JSON.parse(error.request.response);
          this.$vToastify.error(response.message, "Error");
        }
        this.submitStatus = "OK";
      }
    },
  },
};
</script>