<template>
  <div class="min-h-full flex h-screen">
    <div class="flex-1 flex flex-col justify-center">
      <div class="mx-auto w-full max-w-sm lg:w-96">
      <!-- Logo Start -->
      <Logo />
      <!-- Logo End -->
      <!-- Login form End -->
      <form @submit.prevent class=" rounded px-8 pt-6 pb-8 mb-4">
        <h1 class="font-bold text-xl mb-2 text-black mb">Login</h1>
        <div class="mb-4 mt-4">
          <label class="block text-black text-sm font-bold mb-2" for="username">
            E-Mail Address
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && $v.email.$error
                ? 'border-red-500'
                : '',
            ]"
            id="email"
            type="text"
            v-model.trim="$v.email.$model"
            placeholder="user@gmail.com"
          />
          <div class="h-2">
            <div v-if="submitStatus === 'ERROR'">
              <p v-if="!$v.email.required" class="text-red-500 text-xs">
                E-Mail Address is required
              </p>
              <p v-if="!$v.email.email" class="text-red-500 text-xs">
                Please insert a valid E-Mail Address
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label class="block text-black text-sm font-bold mb-2" for="password">
            Password
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="{ 'border-red-500': $v.password.$error }"
            v-model.trim="$v.password.$model"
            id="password"
            type="password"
            placeholder="********"
          />
          <div class="h-6">
            <div v-if="submitStatus === 'ERROR'">
              <p v-if="$v.password.$error" class="text-red-500 text-xs">
                Password is required
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="
              bg-blue-600
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none
            "
            type="submit"
            @click="signIn"
            v-if="submitStatus !== 'PENDING'"
          >
            Login
          </button>
          <button
            type="button"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              leading-6
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-500
              focus:outline-none
              focus:border-indigo-700
              focus:shadow-outline-indigo
              active:bg-indigo-700
              transition
              ease-in-out
              duration-150
              cursor-not-allowed
            "
            disabled=""
            v-if="submitStatus === 'PENDING'"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Login...
          </button>
        </div>
      </form>
      <!-- Reset Password route Start -->
      <router-link
        class="
          inline-block
          align-baseline
          text-sm text-blue-500
          hover:text-blue-800
          px-8
        "
        to="/reset-password"
      >
        Forgot password?
      </router-link>
      <!-- Reset Password route End -->
      <!-- Login form End -->
    </div>
  </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" src="/images/auth.webp" alt="Auth screen image">
    </div>
  </div>
</template>

<script>
import Logo from "../../components/Logo";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    Logo,
  },
  name: "LoginForm",
  data() {
    return {
      submitStatus: null,
      email: "",
      password: "",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    async signIn() {
      let data = {
        email: this.email,
        password: this.password,
      };

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.axios
          .post(`${process.env.VUE_APP_BACKEND_URL}/admin/login`, data)
          .then((dataRes) => {
            const token = dataRes.data.user.token;
            localStorage.setItem("token", token);
            this.$store.dispatch("setToken", token);
            this.$store.dispatch("loadUser", dataRes.data.user);
            this.$router.push("/home");
          })
          .catch((error) => {
            let response = JSON.parse(error.request.response);
            this.$vToastify.error(response.message[0], "Error");
          });
      }
    },
  },
};
</script>