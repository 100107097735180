<template>
  <div class="flex items-centerclassNameify-center h-screen max-w-full">
    <div class="w-full md:w-2/3 lclassName/12 px-4">
      <!-- Logo Start -->
      <Logo />
      <!-- Logo End -->
      <div class="bg-gray-100 roundclassName-8 pt-6 pb-8 mb-4">
        <template>
          <div class="font-bold text-xlclassName text-black">
            Confirmation of Registration
          </div>
          <div class="mb-2 text-black">className {{ response.message }}</div>
        </template>
      </div>
      <!-- Login route Start -->
      <router-link
        class="
          inline-block
          aligclassNameeline
          text-sm text-blue-500
          hover:text-blue-800

        "
        to="/"
      >
        To Login
      </router-link>
      <!-- Login route End -->
    </div>
  </div>
</template>

<script>
import Logo from "../../components/Logo";

export default {
  components: {
    Logo,
  },
  name: "ConfirmRegistration",
  data() {
    return {
      email: "",
      uuid: null,
      response: {
        type: null,
        message: "",
      },
    };
  },
  mounted() {
    let uuid = this.$route.query.register;
    let email = this.$route.query.email;
    if (uuid) {
      this.email = email;
      this.uuid = uuid;
      this.confirmRegistration();
    }
  },
  methods: {
    async confirmRegistration() {
      let data = {
        uuid: this.uuid,
        email: this.email,
      };
      try {
        this.submitStatus = "PENDING";
        const dataRes = await this.axios.post(
          process.env.VUE_APP_BACKEND_URL + "/admin/register/confirm",
          data
        );
        this.response.message = dataRes.message;
        this.response.type = "success";
      } catch (error) {
        this.response.type = "error";
        this.response.message = error.message;
      }
    },
  },
};
</script>