<template>
  <div v-if="isLoggedIn">
    <vue-confirm-dialog></vue-confirm-dialog>
    <div class="flex min-h-screen font-roboto">
      <MenuSidebar />
      <div class="flex-1 flex flex-col overflow-hidden">
        <TopBar />

        <main class="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div class="container mx-auto px-6 py-8">
            <router-view></router-view>
          </div>
        </main>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MenuSidebar from "./components/MenuSidebar";
import TopBar from "./components/TopBar";
import {mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    MenuSidebar,
    TopBar,
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
};
</script>