<template>
  <!-- Pagination Start -->
  <div
    class="
      px-5
      py-5
      bg-white
      flex flex-col
      xs:flex-row
      items-center
      xs:justify-between
    "
  >
    <div class="inline-flex mb-2 xs:mt-0">
      <button
        :disabled="pagesData.current_page <= 1"
        :class="[
          pagesData.current_page <= 1 ? 'opacity-50 cursor-not-allowed' : '',
        ]"
        @click="paginationBack"
        class="
          text-sm
          bg-gray-200
          hover:bg-gray-400
          focus:outline-none
          text-gray-800
          font-semibold
          py-2
          px-4
          rounded-l
        "
      >
        Previous
      </button>
      <button
        v-for="(page, index) in pagesData.last_page"
        :key="index"
        v-show="
          index >= pagesData.current_page - 3 &&
          index <= pagesData.current_page + 2
        "
        @click="changeRoute(`?page=${index + 1}`)"
        :disabled="index + 1 === pagesData.current_page"
        class="
          text-sm
          bg-gray-200
          hover:bg-gray-400
          text-gray-800
          focus:outline-none
          font-semibold
          py-2
          px-4
        "
        :class="[
          index + 1 === pagesData.current_page
            ? 'opacity-50 cursor-not-allowed'
            : '',
        ]"
      >
        {{ index + 1 }}
      </button>
      <button
        :disabled="pagesData.current_page >= pagesData.last_page"
        :class="[
          pagesData.current_page < pagesData.last_page
            ? ''
            : 'opacity-50 cursor-not-allowed',
        ]"
        @click="paginationNext"
        class="
          text-sm
          bg-gray-200
          hover:bg-gray-400
          focus:outline-none
          text-gray-800
          font-semibold
          py-2
          px-4
          rounded-r
        "
      >
        Next
      </button>
    </div>
    <span class="text-xs xs:text-sm text-gray-900"
      >Results {{ pagesData.from }} to {{ pagesData.to }} from
      {{ pagesData.total }} Objects</span
    >
  </div>
  <!-- Pagination End -->
</template>

<script>
export default {
  name: "Pagination",
  props: ["pagesData"],
  methods: {
    async paginationBack() {
      let currentPage = this.pagesData.current_page;
      let url = "?page=" + currentPage;
      if (currentPage > 1) url = "?page=" + (currentPage - 1);
      await this.changeRoute(url);
    },
    async paginationNext() {
      let currentPage = this.pagesData.current_page;
      let url = "?page=" + currentPage;
      if (currentPage < this.pagesData.last_page)
        url = "?page=" + (currentPage + 1);
      await this.changeRoute(url);
    },
    async changeRoute(url) {
      await this.$router.push(url);
      await this.$emit("load-object");
    },
  },
};
</script>