<template>
  <div class="w-full pt-4">
    <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
      <div class="p-3 rounded-full bg-red-300 bg-opacity-75">
        <UserIcon2White />
      </div>

      <div class="mx-5">
        <h4  class="text-2xl font-semibold text-gray-700">
          <p>{{mostOrdersUser.count}}</p>
        </h4>
        <div class="text-gray-500">Most orders: {{mostOrdersUser.email}} </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserIcon2White from "../svg/UserIcon2White";

export default {
  components: {
    UserIcon2White
  },
  props: ["orders"],
  data() {
    return {
      objects: 0,
      desc: false,
    };
  },
  computed: {
    ordersByUser() {
      return this.orders.reduce((acc, order) => {
        const email = order.email;
        if (!acc[email]) {
          acc[email] = {
            email: email,
            count: 0
          };
        }
        acc[email].count++;
        return acc;
      }, {});
    },
    mostOrdersUser() {
      return Object.values(this.ordersByUser).reduce((max, user) => (user.count > max.count) ? user : max, { count: -Infinity });
    }
  }
};
</script>