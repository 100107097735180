<template>
  <div class="w-full pt-4">
    <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
      <div class="p-3 rounded-full bg-green-300 bg-opacity-75">
        <EuroIconWhite />
      </div>

      <div class="mx-5">
        <h4  class="text-2xl font-semibold text-gray-700">
          {{totalIncome}} rsd
        </h4>
        <div class="text-gray-500">Total income</div>
      </div>
    </div>
  </div>
</template>

<script>
import EuroIconWhite from "../svg/EuroIconWhite";

export default {
  components: {
    EuroIconWhite,
  },
  props: ["orders"],
  computed: {
    totalIncome() {
      return this.orders.reduce((acc, order) => {
        if (order.status !== 3 && order.status !== 5 && order.status !== 6) {
          acc += order.total;
        }
        return acc;
      }, 0);
    }
  }
};
</script>