<template>
  <div class="w-full pt-4">
    <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
      <div class="p-3 rounded-full bg-red-300 bg-opacity-75">
        <UserIconWhite />
      </div>

      <div class="mx-5">
        <h4  class="text-2xl font-semibold text-gray-700">
          {{ countUnique(orders, 'email') }}
        </h4>
        <div class="text-gray-500">Different Customers</div>
      </div>
    </div>
  </div>
</template>

<script>
import UserIconWhite from "../svg/UserIconWhite.vue";

export default {
  components: {
    UserIconWhite,
  },
  props: ["orders"],
  data() {
    return {
      objects: 0,
      desc: false,
    };
  },
  methods:{
    countUnique( array, key) {
      return [...new Set(array.map(item => item[key]))].length;
    }
  }
};
</script>