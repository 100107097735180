<template>
  <div>
    <h3 class="text-gray-900 text-3xl font-bold pb-12">{{ title }}</h3>
    <div
        class="
        w-full
        flex flex-col
        items-center
        px-4
        py-2
        rounded-lg
        tracking-wide
        border-2 border-dashed border-blue
        cursor-pointer
        hover:bg-blue hover:text-blue-500
        text-2xl
      "
        @click="modal = !modal"
    >
      +
    </div>
    <div v-if="!error && pagesData.length" class="flex flex-col mt-8">
      <div class="flex justify-center pt-8">
        <div class="bg-white rounded-lg w-full" v-if="pagesData">
          <ul class="divide-y divide-gray-300">
            <li
              class="
                grid
                lg:grid-cols-3
                gap-4
                p-4
                hover:bg-gray-50
                cursor-pointer
                text-left text-xs
                leading-4
                font-medium
                text-gray-500
                uppercase
              "
            >
              <div>Price</div>
              <div>Time</div>
              <div class="flex gap-8 justify-end">Actions</div>
            </li>
            <li
              class="grid lg:grid-cols-3 gap-4 p-4 hover:bg-gray-50"
              v-for="price in pagesData"
              :key="price.id"
            >
              <div>{{ price.price_lg }} rsd</div>

              <div>{{ price.title }}</div>

              <div class="flex gap-8 justify-end">
                <div class="text-right" @click="editItem(price)">
                  <EditIcon />
                </div>
                <div class="text-right" @click="confirmDeleteItem(price.id)">
                  <DeleteIcon />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="text-gray-900 text-3xl font-bold mt-8">There are no prices..</h1>
    </div>
    <!-- Modal Start -->
    <AddPrice
        :modal="modal"
        @closeModal="closeModal"
        @reloadObjects="loadObject"
    />
    <EditPrice
        :modal="modalEdit"
        :item="item"
        @closeModal="closeModal"
        @reloadObjects="loadObject"
    />
    <!-- Modals End -->
  </div>
</template>

<script>
import AddPrice from "../components/AddPrice";
import EditPrice from "../components/EditPrice";
import EditIcon from "../components/svg/EditIcon.vue";
import DeleteIcon from "../components/svg/DeleteIcon.vue";
export default {
  components: {
    EditPrice,
    AddPrice,
    EditIcon,
    DeleteIcon,
  },
  data() {
    return {
      pagesData: {},
      error: false,
      title: "",
      modal: false,
      modalEdit: false,
      item: null,
      path: process.env.VUE_APP_BACKEND_ASSETS_PATH,
    };
  },
  mounted() {
    this.loadObject();
  },
  methods: {
    closeModal() {
      this.modal = false;
      this.modalEdit = false;
    },
    confirmDeleteItem(id) {
      this.$confirm({
        message: `Wanna delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteItem(id);
          }
        },
      });
    },
    deleteItem(id) {
      this.axios
          .post(process.env.VUE_APP_BACKEND_URL + "/price/delete/" + id)
          .then((dataRes) => {
            this.$vToastify.success(dataRes.data.message, "Success");
            this.loadObject();
          })
          .catch((error) => {
            this.$vToastify.error(error.message, "Error");
            this.submitStatus = "OK";
          });
    },
    editItem(item) {
      this.modalEdit = true;
      this.item = item;
    },
    loadObject() {
      let url = process.env.VUE_APP_BACKEND_URL + "/prices/get";
      if (this.$route.query) {
        let page = this.$route.query.page;
        if (page) url = `${url}?page=${page}`;
      }
      this.axios
          .get(url)
          .then((dataRes) => {
              this.pagesData = dataRes.data.prices;
              this.title = "Prices";
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
};
</script>

