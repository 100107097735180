import Vue from "vue";
import VueRouter from 'vue-router'
import store from "../store";

// Views Admin
import Login from '../Views/Auth/Login'
import ConfirmRegistration from '../Views/Auth/ConfirmRegistration'
import ConfirmDelete from '../Views/Auth/ConfirmDelete'
import ResetPassword from '../Views/Auth/ResetPassword'
import ChangePassword from '../Views/Auth/ChangePassword'
import SetNewPassword from '../Views/Auth/SetNewPassword'
import EditUserProfile from '../Views/Auth/EditUserProfile'
import TokenExpired from '../Views/Auth/TokenExpired'

import Home from '../Views/Home'
import Customers from '../Views/Customers'
import Prices from '../Views/Prices'
import NotFound from '../Views/NotFound'

import Orders from '../Views/Orders'
import OrderDetail from '../Views/OrderDetail'


Vue.use(VueRouter);

const routes = [
  // Auth Routes
  {
    path: '/',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: '/confirm-registration',
    component: ConfirmRegistration,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: '/delete-account',
    component: ConfirmDelete,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: '/session-expired',
    component: TokenExpired,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: '/passwort/set-new',
    component: SetNewPassword,
    props: (route) => ({
      query: route.query.token
    }),
    beforeEnter: (to, from, next) => {
      if (!store.getters.isLoggedIn) {
        next();
      } else {
        next("/home");
      }
    }
  },
  // Dashboard Routes
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/order-detail/:id',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/prices',
    name: 'Prices',
    component: Prices,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profil/edit',
    component: EditUserProfile,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/profil/change-password',
    component: ChangePassword,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "*",
    name: "Page not found",
    component: NotFound
  }
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;