<template>
  <div>
    <h3 class="text-gray-900 text-3xl font-bold">{{ title }}</h3>
    <div v-if="!error" class="flex flex-col mt-8">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="
            align-middle
            inline-block
            min-w-full
            shadow
            overflow-hidden
            sm:rounded-lg
            border-b border-gray-200
          "
        >
          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Name</span>
                </th>
                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Email </span>
                </th>
                <th
                    class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Phone </span>
                </th>
                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Order from </span>
                </th>
                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Order to </span>
                </th>
                <th
                    class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Price </span>
                </th>
                <th
                    class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Status </span>
                </th>
                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Actions </span>
                </th>
<!--                <th
                  class="
                    px-6
                    py-3
                    border-b border-gray-200
                    bg-white
                    text-left text-xs
                    leading-4
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  <span class="flex items-center"> Payment </span>
                </th>-->
                <th class="border-b border-gray-200 bg-white"></th>
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr v-for="(order, index) in pagesData.data" :key="index">
                <td
                  class="px-2 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ order.full_name }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                    class="px-2 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ order.email }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                    class="px-2 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ order.phone }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ order.date_from | moment("DD.MM.YYYY") }} -  {{ order.time_from }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div class="flex items-center">
                    <div class="ml-4">
                      <div class="text-sm leading-5 font-medium text-gray-900">
                        {{ order.date_to | moment("DD.MM.YYYY") }}  - {{ order.time_to  }}
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="
                    px-6
                    py-4
                    whitespace-no-wrap
                    border-b border-gray-200
                    whitespace-nowrap
                  "
                >
                  <div class="text-xs leading-5 text-gray-500">
                    {{ order.total }} din
                  </div>
                </td>


                <td
                  class="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                >
                  <div
                    class="
                      inline-flex
                      text-xs
                      text-center
                      leading-5
                      font-semibold
                      rounded-full
                      overflow-hidden
                    "
                  >
                    <span
                      class="px-2 bg-yellow-100 text-yellow-800"
                      v-if="order.status === 1"
                      >Reserved</span
                    >
                    <span
                        class="px-2 bg-blue-100 text-blue-800"
                        v-if="order.status === 2"
                    >Parked</span
                    >
                    <span
                        class="px-2 bg-red-100 text-red-800"
                      v-else-if="order.status === 3"
                      >Cancelled</span
                    >
                    <span
                        class="px-2 bg-red-100 text-red-800"
                        v-else-if="order.status === 6"
                    >Dors locked</span
                    >
                    <span
                      class="px-2 bg-green-100 text-green-800"
                      v-else-if="order.status === 4"
                      >Completed</span
                    >
                    <span
                        class="px-2 bg-green-100 text-green-800"
                        v-else-if="order.status === 5"
                    >Free</span
                    >
                  </div>
                </td>

                <td
                  class="
                    px-6
                    py-4
                    whitespace-no-wrap
                    text-right
                    flex
                    border-b border-gray-200
                    text-sm
                    leading-5
                    font-medium
                  "
                >
                  <div
                    class="text-gray-600 hover:text-gray-400 mr-3"
                    @click="editItem(order)"
                  >
                    <EditIcon />
                  </div>
                  <router-link
                      class="text-gray-600 hover:text-gray-400"
                      :to="`/order-detail/${order.id}`"
                  >
                    <ViewIcon />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Pagination Start -->
          <Pagination :pagesData="pagesData" @load-object="loadObject" />
          <!-- Pagination End -->
        </div>
        <EditOrder
            :modal="modalEdit"
            :item="item"
            @closeModal="closeModal"
            @reloadObjects="loadObject"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pagination from "../components/Pagination";
import EditIcon from "../components/svg/EditIcon.vue";
import ViewIcon from "../components/svg/ViewIcon";
import EditOrder from "../components/EditOrder";

export default {
  components: {
    ViewIcon,
    Pagination,
    EditIcon,
    EditOrder
  },
  data() {
    return {
      pagesData: {},
      error: false,
      title: "",
      path: process.env.VUE_APP_BACKEND_ASSETS_PATH,
      modalEdit: false,
      item: null
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    closeModal() {
      this.modal = false;
      this.modalEdit = false;
    },
    editItem(item) {
      this.modalEdit = true;
      this.item = item;
    },
    loadObject() {
      let url = process.env.VUE_APP_BACKEND_URL + "/order/get?paginate=30";

      if (this.$route.query) {
        let page = this.$route.query.page;
        if (page) url = `${url}&page=${page}`;
      }
      this.axios
        .get(url)
        .then((dataRes) => {
            this.pagesData = dataRes.data.orders;
            this.pagesData.data.sort((a, b) => (a.date_from < b.date_from ? 1 : -1))

            this.title = "Orders";
        })
        .catch((error) => {
          console.log(error);
          this.error = true;
          this.title = "There are no Orders in the Database";
        });
    },
    euroCurrencyFormat(val) {
      return new Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(val));
    },
  },
  mounted() {
    this.loadObject();
  },
};
</script>
