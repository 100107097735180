<template>
  <div class="flex items-center justify-center h-screen max-w-full">
    <div class="w-full md:w-2/3 px-4">
      <!-- Password reset Start -->
      <form @submit.prevent class="bg-white rounded px-8 pt-6 pb-8 mb-4">
        <h1 class="font-bold text-xl mb-2 text-black">Change password</h1>
        <div class="mb-2">
          <label
            class="block text-black text-sm font-bold mb-2"
            for="passwordOld"
          >
            Old password
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && $v.password.old.$error
                ? 'border-red-500'
                : '',
            ]"
            v-model.trim="$v.password.old.$model"
            id="passwordOld"
            type="password"
            placeholder="********"
          />
          <div class="h-2">
            <div v-if="submitStatus === 'ERROR'">
              <p v-if="!$v.password.old.required" class="text-red-500 text-xs">
                Password is required
              </p>
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label
            class="block text-black text-sm font-bold mb-2"
            for="passwordNew"
          >
            New passwort
          </label>
          <input
            class="
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              text-black
              leading-tight
              focus:outline-none
            "
            :class="[
              submitStatus === 'ERROR' && $v.password.new.$error
                ? 'border-red-500'
                : '',
            ]"
            v-model.trim="$v.password.new.$model"
            id="passwordNew"
            type="password"
            placeholder="********"
          />
          <div class="h-8">
            <div v-if="submitStatus === 'ERROR'">
              <p
                v-if="!$v.password.new.specialCharAlphaNumbers"
                class="text-red-500 text-xs"
              >
                The password requires at least 8 characters incl Numbers and
                special characters
              </p>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="
              bg-blue-600
              hover:bg-blue-700
              text-white
              font-bold
              py-2
              px-4
              rounded
              focus:outline-none
            "
            type="submit"
            @click="changePass"
            v-if="submitStatus !== 'PENDING'"
          >
            Save new password
          </button>
          <button
            type="button"
            class="
              inline-flex
              items-center
              px-4
              py-2
              border border-transparent
              text-base
              leading-6
              font-medium
              rounded-md
              text-white
              bg-indigo-600
              hover:bg-indigo-500
              focus:outline-none
              focus:border-indigo-700
              focus:shadow-outline-indigo
              active:bg-indigo-700
              transition
              ease-in-out
              duration-150
              cursor-not-allowed
            "
            disabled=""
            v-if="submitStatus === 'PENDING'"
          >
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Saving...
          </button>
        </div>
      </form>
      <!-- Password reset form End -->
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "ChangePassword",
  data() {
    return {
      submitStatus: null,
      password: {
        old: "",
        new: "",
      },
    };
  },
  validations: {
    password: {
      old: {
        required,
      },
      new: {
        required,
        specialCharAlphaNumbers(password) {
          return (
            /[a-z]/.test(password) && // checks for a-z
            /[0-9]/.test(password) && // checks for 0-9
            /\W|_/.test(password) && // checks for special char
            password.length >= 8
          );
        },
      },
    },
  },
  methods: {
    changePass() {
      let data = {
        oldPassword: this.password.old,
        newPassword: this.password.new,
      };

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.axios
          .post(
            `${process.env.VUE_APP_BACKEND_URL}/admin/password/change`,
            data
          )
          .then((dataRes) => {
            this.$vToastify.success(dataRes.data.message, "Success");
            this.password.old = "";
            this.password.new = "";
          })
          .catch((error) => {
            let response = JSON.parse(error.request.response);
            this.$vToastify.error(response.message, "Error");
          });
      }
    },
  },
};
</script>