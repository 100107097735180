<template>
  <div>
    <h3 class="text-gray-900 text-3xl font-bold">{{ title }}</h3>
    <div v-if="!error && pagesData.data" class="flex flex-col mt-8">
      <div class="flex justify-center pt-8">
        <div class="bg-white rounded-lg w-full" v-if="uniqueCustomers">
          <ul class="divide-y divide-gray-300">
            <li
              class="
                grid
                lg:grid-cols-3
                gap-4
                p-4
                hover:bg-gray-50
                cursor-pointer
                text-left text-xs
                leading-4
                font-medium
                text-gray-500
                uppercase
              "
            >
              <div>E-Mail Address</div>
              <div>Name</div>
              <div class="flex gap-8 justify-end">Actions</div>
            </li>
            <li
              class="grid lg:grid-cols-3 gap-4 p-4 hover:bg-gray-50"
              v-for="user in uniqueCustomers"
              :key="user.id"
            >
              <div>{{ user.email }}</div>

              <div>{{ user.full_name }}</div>

              <div class="flex gap-8 justify-end">
                <div class="text-right cursor-pointer">
                  <a :href="`mailto:${user.email}`">
                    <EmailIcon />
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="text-gray-900 text-3xl font-bold">There are no customers.</h1>
    </div>
  </div>
</template>
<script>

import EmailIcon from "../components/svg/EmailIcon.vue";
export default {
  components: {
    EmailIcon,
  },
  data() {
    return {
      pagesData: {},
      error: false,
      title: "",
      path: process.env.VUE_APP_BACKEND_ASSETS_PATH,
    };
  },
  computed: {
    uniqueCustomers() {
      const seen = new Set();
      return this.pagesData.data.filter(order => {
        const email = order.email;
        if (seen.has(email)) {
          return false;
        }
        seen.add(email);
        return true;
      });
    }
  },
  methods: {
    loadObject() {
      let url = process.env.VUE_APP_BACKEND_URL + "/order/get?paginate=1000";

      if (this.$route.query) {
        let page = this.$route.query.page;
        if (page) url = `${url}&page=${page}`;
      }
      this.axios
          .get(url)
          .then((dataRes) => {
            this.pagesData = dataRes.data.orders;
            this.title = "Customers";
          })
          .catch((error) => {
            console.log(error);
            this.error = true;
            this.title = "There are no customers in the Database";
          });
    },
    euroCurrencyFormat(val) {
      return new Intl.NumberFormat("en-EN", {
        style: "currency",
        currency: "EUR",
      }).format(parseFloat(val));
    },
  },
  mounted() {
    this.loadObject();
  },
};
</script>

