import Vue from "vue";
import Axios from "axios";
import store from "../store";
import router from "../router";

/*Vue.prototype.$http = ;*/


Axios.interceptors.request.use(config => {
    Axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

    config.headers.common["Accept"] = `application/json`;
    config.headers.common["Content-Type"] = `multipart/form-data`;
    config.headers.common["X-Requested-With"] = `XMLHttpRequest`;

    // Bearer Token
    if (store.state.token) {
      config.headers.common[
        "Authorization"
      ] = `Bearer ${store.state.token}`;
    }

    return config;
});

Axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        const code = error.response ? error.response.status : null;
        switch (code) {
            case 401:
                store.dispatch("removeToken").then(() => {
                    localStorage.removeItem("token");
                    store.dispatch("removeUser", null);
                    router.push("/session-expired").catch(() => {});
                });
                break;
            case 403:
                store.dispatch("removeToken").then(() => {
                    localStorage.removeItem("token");
                    store.dispatch("removeUser", null);
                    router.push("/session-expired").catch(() => {});
                });
                break;
            default:
                break;
        }
        return Promise.reject(error);
    }
);