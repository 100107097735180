<template>
  <div class="w-full pt-4">
    <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white">
      <div class="p-3 rounded-full bg-purple-300 bg-opacity-75">
        <CartIconWhite />
      </div>
      <div class="mx-5">
        <h4 class="text-2xl font-semibold text-gray-700">
          {{ orders.length }}
        </h4>
        <div class="text-gray-500">Orders</div>
      </div>
    </div>
  </div>
</template>

<script>
import CartIconWhite from "../svg/CartIconWhite.vue";

export default {
  components: {
    CartIconWhite,
  },
  props: ["orders"],
};
</script>
