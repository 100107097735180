<template>
  <div>
    <h3 class="text-gray-900 text-3xl font-bold">Income By Month - {{ selectedYear }}</h3>
    <div class="flex mt-4 mb-4">
      <button @click="switchYear(2023)" :class="{ 'bg-purple-200 text-purple-900': selectedYear === 2023 }" class="px-4 py-2 mr-2 rounded cursor-pointer">2023</button>
      <button @click="switchYear(2024)" :class="{ 'bg-purple-200 text-purple-900': selectedYear === 2024 }" class="px-4 py-2 rounded cursor-pointer">2024</button>
    </div>
    <div v-if="orders">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
    <table class="min-w-full divide-y divide-gray-300">
      <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Month</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Income</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">MB</th>
        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">CP</th>
      </tr>
      </thead>
      <tbody class="divide-y divide-gray-200 bg-white">
      <tr v-for="(income, index) in monthlyIncome" :key="index" class="">
        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ months[index] }}</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ income }} rsd</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ income*0.8 }} rsd</td>
        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ income*0.2 }} rsd</td>
      </tr>
      </tbody>
    </table>
  </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orders", "selectedYear"],
  data() {
    return {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }
  },
  computed: {
    monthlyIncome() {
      const filteredOrders = this.orders.filter(order => {
        const orderYear = new Date(order.date_from).getFullYear();
        return orderYear == this.selectedYear;
      });

      const monthlyIncome = Array(12).fill(0);
      filteredOrders.forEach((order) => {
        const start = new Date(order.date_from);
        const end = new Date(order.date_to);
        let month = start.getMonth();
        const endMonth = end.getMonth();

        while (month <= endMonth && month < 12) {
          if (month === endMonth) {
            monthlyIncome[endMonth] += order.total;
          }
          month++;
        }
      });

      return monthlyIncome;
    },
  },
  methods: {
    switchYear(year) {
      this.$emit("switchYear", year);
    },
  },
}
</script>
