var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"min-h-full flex h-screen"},[_c('div',{staticClass:"flex-1 flex flex-col justify-center"},[_c('div',{staticClass:"mx-auto w-full max-w-sm lg:w-96"},[_c('Logo'),_c('form',{staticClass:"rounded px-8 pt-6 pb-8 mb-4",on:{"submit":function($event){$event.preventDefault();}}},[_c('h1',{staticClass:"font-bold text-xl mb-2 text-black"},[_vm._v("Set new password")]),_c('div',{staticClass:"mb-4 mt-4"},[_c('label',{staticClass:"block text-black text-sm font-bold mb-2",attrs:{"for":"username"}},[_vm._v(" E-Mail Address ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.email.$model),expression:"$v.email.$model",modifiers:{"trim":true}}],staticClass:"appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none",class:[
            _vm.submitStatus === 'ERROR' && _vm.$v.email.$error
              ? 'border-red-500'
              : '',
          ],attrs:{"id":"email","type":"text","placeholder":"z.B. lara.neumayer@gmail.com"},domProps:{"value":(_vm.$v.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"h-2"},[(_vm.submitStatus === 'ERROR')?_c('div',[(!_vm.$v.email.required)?_c('p',{staticClass:"text-red-500 text-xs"},[_vm._v(" Please enter email address ")]):_vm._e(),(!_vm.$v.email.email)?_c('p',{staticClass:"text-red-500 text-xs"},[_vm._v(" Please enter correct email address ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"block text-black text-sm font-bold mb-2",attrs:{"for":"password"}},[_vm._v(" Password ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.password.$model),expression:"$v.password.$model",modifiers:{"trim":true}}],staticClass:"appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none",class:[
            _vm.submitStatus === 'ERROR' && !_vm.$v.password.specialCharAlphaNumbers
              ? 'border-red-500'
              : '',
          ],attrs:{"id":"password","type":"password","placeholder":"********"},domProps:{"value":(_vm.$v.password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"h-8"},[(_vm.submitStatus === 'ERROR')?_c('div',[(!_vm.$v.password.specialCharAlphaNumbers)?_c('p',{staticClass:"text-red-500 text-xs"},[_vm._v(" The password requires at least 8 characters inclusive Numbers and special characters ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"flex items-center justify-between"},[(_vm.submitStatus !== 'PENDING')?_c('button',{staticClass:"bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none",attrs:{"type":"submit"},on:{"click":_vm.resetPassword}},[_vm._v(" Set password ")]):_vm._e(),(_vm.submitStatus === 'PENDING')?_c('button',{staticClass:"inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150 cursor-not-allowed",attrs:{"type":"button","disabled":""}},[_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-5 w-5 text-white",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]),_vm._v(" Set password... ")]):_vm._e()])]),_c('router-link',{staticClass:"inline-block align-baseline text-xs text-blue-500 hover:text-blue-800 px-8",attrs:{"to":"/"}},[_vm._v(" Back to Login ")])],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden lg:block relative w-0 flex-1"},[_c('img',{staticClass:"absolute inset-0 h-full w-full object-cover",attrs:{"src":"/images/auth.webp","alt":"Auth screen image"}})])
}]

export { render, staticRenderFns }