<template>
  <div class="flex items-center justify-center h-screen max-w-full">
    <div class="w-full md:w-2/3 lg:w-5/12 px-4">
      <!-- Logo Start -->
      <Logo />
      <!-- Logo End -->
      <div class="bg-gray-100 rounded px-8 pt-6 pb-8 mb-4">
        <template
        ><div class="font-bold text-xl mb-2 text-black">
          Your session has expired.
        </div>
          <div class="text-black">Please log in again.</div>
        </template>
      </div>
      <!-- Login route Start -->
      <router-link
          class="inline-block align-baseline text-sm text-blue-500 hover:text-blue-800"
          to="/"
      >
        To login
      </router-link>
      <!-- Login route End -->
    </div>
  </div>
</template>

<script>
import Logo from "../../components/Logo";
export default {
  components: {
    Logo,
  },
  name: "TokenExpired",
};
</script>